import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import { Disqus, CommentCount } from "gatsby-plugin-disqus"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import lightTheme from "../themes/light"
import darkTheme from "../themes/dark"

export default function Blogpost({ data, location }) {
  const [isDarkMode, setIsDarkMode] = useState(false)
  const { siteUrl } = useSiteMetadata()

  const Container = styled.div`
    background: ${props => props.theme.colors.background};
  `

  const BlogPost = styled.div`
    display: grid;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    color: ${props => props.theme.colors.textColor};
    .heading {
      display: grid;
      text-align: center;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
    h1,
    h2,
    h3,
    h4 {
      color: ${props => props.theme.colors.textColor};
    }
    #title {
      color: ${props => props.theme.colors.textColor};
      text-align: center;
      font-size: 36px;
      grid-column: span 2;
    }
    #byline {
      grid-column: span 1;
      text-align: center;
      color: #f15da6;
      font-size: 18px;
    }
    .content {
      font-size: 22px;
      color: ${props => props.theme.colors.textColor};
    }
    @media (max-width: 769px) {
      width: 90%;
      #title {
        font-size: 26px;
      }
      h1 {
        font-size: 25px;
      }
      p {
        font-size: 18px;
      }
    }
  `

  const CheckBoxWrapper = styled.div`
    position: relative;
  `
  const CheckBoxLabel = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    width: 42px;
    height: 26px;
    border-radius: 15px;
    background: #332f2f;
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin: 3px;
      background: #ffffff;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
      transition: 0.4s;
    }
  `
  const CheckBox = styled.input`
    opacity: 0;
    z-index: 1;
    border-radius: 15px;
    width: 42px;
    height: 26px;
    &:checked + ${CheckBoxLabel} {
      background: #c9c9c9;
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        margin-left: 21px;
        transition: 0.4s;
      }
    }
  `

  const switchTheme = isDarkMode => {
    setIsDarkMode(isDarkMode)
  }

  const post = data.markdownRemark

  let disqusConfig = {
    url: `${siteUrl + location.pathname}`,
    identifier: post.id,
    title: post.title,
  }

  return (
    <Layout>
      <Container theme={isDarkMode ? darkTheme : lightTheme}>
        <BlogPost theme={isDarkMode ? darkTheme : lightTheme}>
          <div className="heading">
            <h1 id="title">{post.frontmatter.title}</h1>
            <h4 id="byline">
              By {post.frontmatter.author} on {post.frontmatter.date}
            </h4>
            <CheckBoxWrapper>
              <CheckBox
                id="checkbox"
                type="checkbox"
                onChange={e => switchTheme(e.target.checked ? true : false)}
                checked={isDarkMode}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </CheckBoxWrapper>
          </div>
          <CommentCount config={disqusConfig} placeholder={"..."} />
          <article
            className="content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          ></article>
          <Disqus config={disqusConfig} />
        </BlogPost>
      </Container>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        author
        date
        spoiler
        cover {
          publicURL
          childImageSharp {
            sizes(maxWidth: 2000) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
